import { graphql, useStaticQuery } from "gatsby";
import React, { useState } from "react";
import { getTheme, isMobile } from "../../functions/theme";
import HomeSecondaryTitle from "./home-secondary-title";

const voicesPriority = ['Consulting', 'Development', 'Design'];

const voices: ServicesElement[] = [
  {
    title: `Consulting`,
    description: `We work with you to better understand the true potentiality of your ideas. Before writing a single line of code, we thoroughly diagnose the problem to highlight the challenges and needs and translate them into specific technical requirements to design the most viable solution for your purposes.`,
    voices: [
      { label: `Lorem Ipsum`, url: `` },
      { label: `Lorem Ipsum`, url: `` },
      { label: `Lorem Ipsum`, url: `` },
    ]
  },
  {
    title: `Development`,
    description: `use it, break it, fix it, trash it, change it, mail, upgrade it
charge it, point it, zoom it, press it, snap it, work it, quick erase it
write it, cut it, paste it, save it, load it, check it, quick rewrite it
plug it, play it`,
    voices: [
      { label: `Education & Experience`, url: `` },
      { label: `Engineering & Fantasy`, url: `` },
      { label: `Security & Audit`, url: `` },
    ]
  },
  {
    title: `Design`,
    description: `We combine business opportunities with customer expectations to design, transform and deliver useful products, unique brand experiences, developing an end to end actual value.`,
    voices: [
      { label: `Comic Sans & Helvetica`, url: `` },
      { label: `Lorem Ipsum`, url: `` },
      { label: `Lorem Ipsum`, url: `` },
    ]
  },
]

const HomeOurServices = (props: {
  targetRef: any,
}) => {

  const staticQuery: any = useStaticQuery(query);

  const _isMobile: boolean = isMobile();

  const currentTheme: "light" | "dark" = getTheme();

  const serviceList: ServicesListElement[] = staticQuery.allStrapiComponentHomeServiceList.edges.map((el: any) => {
    return {
      title: el.node.title,
      serviceGroupName: el.node.serviceGroupName,
      link: el.node.link,
    } as ServicesListElement;
  })

  const _services: ServicesElement[] = staticQuery.allStrapiComponentHomeServices.edges.map((el: any) => {
    return {
      priority: voicesPriority.findIndex(_el => _el == el.node.name)+1,
      title: el.node.name,
      description: el.node.description,
      voices: serviceList.filter(_el => _el.serviceGroupName == el.node.name)?.map(__el => {
        return {
          label: __el.title,
          url: __el.link
        }
      }) || []
    } as ServicesElement;
  }).sort((a: ServicesElement, b: ServicesElement) => a.priority && b.priority && (a.priority - b.priority))

  // console.log(_services);

  const [services, setServices] = useState<ServicesElement[]>(_services);

  const _pageText: { servicesTitle: string } = staticQuery.strapiHome;

  const [pageText, setPageText] = useState<{servicesTitle: string}>(_pageText);

  return (

    <div className="flex flex-col min-h-screen px-4 mb-40 lg:px-32 md:px-16" ref={props?.targetRef && props.targetRef}>
      <div className="sm:mt-7 lg:mb-14 2xl:my-20">
        <HomeSecondaryTitle smallTitle="EXPERTISE" mainTitle={pageText?.servicesTitle}/>
      </div>

      {/* WITHOUT MOBILE VERSION [NEW VERSION | 3 Rows] */}
      <div className="flex flex-col w-full h-full lg:flex-row 2xl:flex-col lg:space-x-28 2xl:space-x-0">
        {
          services.map((service, index) => (
            <div className="flex flex-col pt-16 2xl:border-t 2xl:flex-row border-cardinal-white lg:pt-0 lg:w-1/3 2xl:w-full" key={index + '_our_services'}>
              <div className="flex pb-3 text-2xl font-medium sm:pb-5 2xl:h-full 2xl:w-2/5 2xl:text-4xl 2xl:pt-12 2xl:pl-6" key={index + '_our_services_title'}>
                {service.title}
              </div>
              <div className={`flex flex-col h-full text-base font-light 2xl:w-3/5 2xl:pt-12 xl:text-2xl ${index == voices.length - 1 ? 'font-family-comic' : ''}`}
                placeholder={`${index == voices.length - 1 ? '*Make it bold, make it magic*' : ''}`}
                key={index + '_description_our_services'}>
                <span className="h-full 2xl:pr-20 pb-9 2xl:pb-0" key={index + '_description_our_services_s'}>
                  {service.description}
                </span>
                <div className="flex flex-wrap border-t 2xl:border-t-0 border-cardinal-white 2xl:pt-20 2xl:pb-28" key={index + '_voices_our_services'}>{/* grid grid-cols-2 */}
                  {
                    service.voices && service.voices.map((voice, index) => (
                      <div className={`flex justify-start w-full py-4 text-base 2xl:text-2xl border-b 2xl:border-b-0 2xl:w-1/2 lg:py-5 ${index == 3 && 'sm:hidden 2xl:flex'}`} key={index + '_link_label_our_services'}>
                        <span key={index + '_link_label_our_services_s'}>{voice.label}</span>
                        {/* className='cursor-pointer undeline-animation undeline-animation-white' */}
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>
          ))
        }
      </div>
    </div>

  )

}

export default HomeOurServices;

export const query = graphql`
    query {
      allStrapiComponentHomeServices {
        edges {
          node {
            name
            description
            children {
              id
            }
          }
        }
      }
      strapiHome {
        servicesTitle
      }
      allStrapiComponentHomeServiceList {
        edges {
          node {
            title
            serviceGroupName
          }
        }
      }
    }
`;

export type ServicesElement = {
  priority?: number,
  title: string
  description: string
  voices: { label: string, url: string }[] | undefined
}

export type ServicesListElement = {
  title: string,
  serviceGroupName: string,
  link: string
}